import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import Helmet from "react-helmet";

export const ProductsPageTemplate = ({
  heading,
  description,
  heading_one,
  description_one,
  heading_two,
  description_two,
  heading_three,
  description_three,
  heading_four,
  description_four,
  heading_five,
  description_five,
  products_images,
  cam1,
  cam2,
  cam3,
  cam4,
  cam5,
}) => (
    <>
      <Helmet>
        <title>Services | Globelynx</title>
        <meta name="og:title" content="Globelynx - Services" />
        <meta
          name="description"
          content="Globelynx offers a range of add-on services including advice on live streaming, media training, editorial advice and promotion"
        />
      </Helmet>


      <div className="container-fluid">
        <div className="row">
          <div className="has-text-centered has-padding">
            <h1>{heading}</h1>
            <p>{description}</p>
          </div>
        </div>
      </div>

      <div className="is-fullwidth has-background-gray">
        <div className="container-fluid">
          <div className="row has-padding">
            <div className="three columns">
              {/* <img
							style={{
								height: '250px',
								objectFit: 'contain',
							}}
						src={pic4} /> */}
              <Img fluid={cam1.childImageSharp.fluid} />
            </div>
            <div className="nine columns">
              <h2 className="custom-heading">{heading_one}</h2>
              <p>{description_one}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row has-padding">
          <div className="three columns">
            <Img fluid={cam2.childImageSharp.fluid} />
          </div>
          <div className="nine columns">
            <h2 className="custom-heading">{heading_two}</h2>
            {/* <p>{description_two} </p> */}
            <p>Do you have sought-after experts who are reluctant to go on camera? We can provide bespoke training to help break down barriers, take away the fear factor and explain how to prep for live TV interviews.  All our trainers are experienced TV news journalists. Contact us here for details: <a href="mailto:Mail@Globelynx.com">Mail@Globelynx.com</a></p>
          </div>
        </div>
      </div>

      <div className="is-fullwidth has-background-gray">
        <div className="container-fluid">
          <div className="row has-padding">
            <div className="three columns">
              <Img fluid={cam3.childImageSharp.fluid} />
            </div>
            <div className="nine columns">
              <h2 className="custom-heading">{heading_three}</h2>
              {/* <p>{description_three}</p> */}
              <p>This is the editorial side of our business - a team of journalists led by a former TV news exec with more than 20 years’ experience covering stories in the UK and abroad. They talk with broadcasters every day, provide clients with a daily news list and a weekly lookahead of stories on which TV producers are looking for expert comment. News channels are made aware of available experts via our email alert system. They also come to us directly with requests or browse our expert database <Link to="/experts" style={{ color: 'blue' }}>here</Link>. We are a vital newsgathering tool for many news channels who rely on our editorial nous and our range of quality experts.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row has-padding">
          <div className="three columns">
            <Img fluid={cam4.childImageSharp.fluid} />
          </div>
          <div className="nine columns">
            <h2 className="custom-heading">{heading_four}</h2>
            <p>{description_four}</p>
          </div>
        </div>
      </div>

      <div className="is-fullwidth has-background-gray">
        <div className="container-fluid">
          <div className="row has-padding">
            <div className="three columns">
              <Img fluid={cam5.childImageSharp.fluid} />
            </div>
            <div className="nine columns">
              <h2 className="custom-heading">{heading_five}</h2>
              <p>{description_five}</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className=" is-fullwidth">
				<div className="container-fluid">
					<div className="row has-padding has-text-centered"> */}
      {/* <h2 className="custom-heading">{featuredCameras.heading}</h2> */}
      {/* <ServicesList productsItems={products_images.product} />  */}
      {/* <Link to="camerasDetails/Cameras-sony-camera">
							<Features gridItems={featuredCameras.cameras} columnSize="three" />
						</Link> */}
      {/* </div>
				</div>
			</div> */}

      {/* <div style={{display:'hidden'}}>
				<span>{PageContent}</span>
			</div> */}
    </>
  );

ProductsPageTemplate.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  heading_one: PropTypes.string,
  description_one: PropTypes.string,
  heading_two: PropTypes.string,
  description_two: PropTypes.string,
  heading_three: PropTypes.string,
  description_three: PropTypes.string,
  heading_four: PropTypes.string,
  description_four: PropTypes.string,
  heading_five: PropTypes.string,
  description_five: PropTypes.string,
  products_images: PropTypes.shape({
    heading: PropTypes.string,
    product: PropTypes.array,
  }),
  cam1: PropTypes.object,
  cam2: PropTypes.object,
  cam3: PropTypes.object,
  cam4: PropTypes.object,
  cam5: PropTypes.object,
};

const ProductsPage = ({ data }) => {
  const { frontmatter: products } = data.productsPageData.edges[0].node;
  const cam1 = data.imageOne;
  const cam2 = data.imageTwo;
  const cam3 = data.imageThree;
  const cam4 = data.imageFour;
  const cam5 = data.imageFive;

  return (
    <Layout>
      <ProductsPageTemplate
        heading={products.heading}
        description={products.description}
        heading_one={products.heading_one}
        description_one={products.description_one}
        heading_two={products.heading_two}
        description_two={products.description_two}
        heading_three={products.heading_three}
        description_three={products.description_three}
        heading_four={products.heading_four}
        description_four={products.description_four}
        heading_five={products.heading_five}
        description_five={products.description_five}
        products_images={products.products_images}
        cam1={cam1}
        cam2={cam2}
        cam3={cam3}
        cam4={cam4}
        cam5={cam5}
      />
    </Layout>
  );
};

ProductsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductsPage;

export const ProductsPageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "services_4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "services_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "services_7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "services_6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFive: file(relativePath: { eq: "services_5.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productsPageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "products-page" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            heading
            description
            heading_one
            description_one
            heading_two
            description_two
            basic_description
            heading_three
            description_three
            heading_four
            description_four
            heading_five
            description_five
            products_images {
              product {
                image {
                  childImageSharp {
                    fluid(maxWidth: 240, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                text
              }
              heading
            }
          }
        }
      }
    }
  }
`;
